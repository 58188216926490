













import { defineComponent } from "@vue/composition-api";
import ScheduleWrapper from "@/components/grouplessons/ScheduleWrapper.vue";
import { useCurrentGym } from "@/lib/hooks/useCurrentGym";

export default defineComponent({
	components: {
		ScheduleWrapper,
	},
	setup() {
		const { id: currentGymId } = useCurrentGym();

		return { currentGymId };
	},
});
